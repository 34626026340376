<template>
  <transition :appear="true" name="slide">
    <div class="answer-home" :style="anho_st">
      <div class="header">
        <div class="back" @click="back">
          <div class="icon"></div>
          <div class="name">返回</div>
        </div>
      </div>
      <div class="sharebtn" v-if="activityInfo.activity_guid && sharebtn==1" @click="share">分享</div>
      <router-link tag="div" to="/login" class="answer-notice" v-if="!c_user_dzz">
        您尚未绑定读者证,未绑定读者证无法参与领奖。点此立即绑定读者证
      </router-link>
      <div class="answer-notice2">请先看视频再答题，看完视频自动进入答题页面进行答题。</div>
      <div tag="div" class="btn" :style="btn_st1" @click="toanswer" v-if="userReadCard">看视频答题</div>
      <router-link tag="div" :to="'/home/answer-home-new/answer-rank'" :style="btn_st" class="rank">排行榜</router-link>
      <router-view></router-view>
      <edit-userinfo-agency v-if="editinfo || editagency" v-on:getCUserInfoNew="_getCUserInfoNew" :c_user_phone="c_user_phone" :c_user_name="c_user_name" :activity="activity" v-on:getuseragency="_useragency"></edit-userinfo-agency>
      <act-video-detail guid="d6b2af6f-8ae6-3cc6-8a72-72bb560187ff" name="视频" v-if="hassee==0" v-on:_hassee="_hassee"></act-video-detail>
      <overlay
        :show="showShare"
      >
        <activity-share-new
        :show="showShare"
          :pic-url="activityInfo.activity_cover_base64"
          :name="activity.activity_title"
          :author="activity.activity_title"
          :user-name="c_user_name"
          :agency-guid="agencyGuid"
          :user-guid="userGuid"
          :start-time="activity.activity_start_date"
          :end-time="activity.activity_end_date"
          :addr="activity.activity_addr"
          :activity_type_new = "activity.activity_type_new"
          :activity_guid = "activity.activity_guid"
          @shareClose="showShare=false"
        ></activity-share-new>
      </overlay>
    </div>
  </transition>
</template>

<script>
import { Overlay } from 'vant'
import { mapGetters } from 'vuex'
import { useragency } from '@/api/Questions'
import { getCurrentConnect, getActivitiesDetail } from '@/api/Activity'
import { getCUserInfoNew } from '@/api/Cuser'
import ActVideoDetail from '../act-video-detail/VideoDetail.vue'
import ActivityShareNew from '@/components/activity/ActivityShareNew'
import EditUserinfoAgency from '@/components/activity/EditUserinfoAgency'

export default {
  name: 'AnswerHome',
  components: {
    ActVideoDetail, Overlay, ActivityShareNew, EditUserinfoAgency
  },
  computed: {
    ...mapGetters(['userReadCard', 'userGuid', 'agencyGuid'])
  },
  data () {
    return {
      activity: {},
      anho_st: '',
      btn_st: '',
      btn_st1: '',
      editinfo: false,
      activity_guid: '',
      hassee: 2,
      activityInfo: {},
      showShare: false,
      c_user_name: '',
      c_user_phone: '',
      c_user_dzz: '',
      sharebtn: 1,
      editagency: false
    }
  },
  created () {
    this.preserveQueryParams()
    this.activity_guid = this.$route.query.activity_guid ? this.$route.query.activity_guid : ''
    if (this.activity_guid === '') {
      this.$router.replace('/')
    }
    this._getCurrentConnect()
    this._getCUserInfoNew()
  },
  methods: {
    preserveQueryParams () {
      const currentQuery = this.$route.query
      const queryString = Object.keys(currentQuery)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(currentQuery[key])}`)
        .join('&')
      if (queryString) {
        window.location.search = queryString
      }
    },
    share () {
      // this.htmlToCanvas()
      console.log('activity_home_bg_img--', this.activity_home_bg_img)
      this.showShare = true
    },
    _hassee () {
      this.hassee = 1
      if (this.hassee === 1) {
        const currentTime = new Date().getTime()
        const now = Math.floor(currentTime / 1000)
        if (this.activity.activity_start_time_int > now) {
          this.$toast.fail('活动还未开始')
          return false
        }
        if (this.activity.activity_end_time_int < now) {
          this.$toast.fail('活动已结束')
          return false
        }
        if (!this.activity || !this.activity.activitySetting) {
          this.$toast.fail('活动未开始')
          return false
        } else {
          this.$router.push('/home/answer-home-new/answer-question')
        }
      }
    },
    _useragency () {
      useragency({ c_user_guid: this.userGuid, activity_guid: this.activity.activity_guid }).then(res => {
        if (res.code === 200) {
          if (!res.data) {
            this.editagency = true
          } else {
            if (!res.data.rank_agency) {
              this.editagency = true
            } else {
              this.editagency = false
            }
          }
        }
      })
    },
    _getCUserInfoNew () {
      getCUserInfoNew({ c_user_guid: this.userGuid }).then(res => {
        if (res.code === 200) {
          this.c_user_name = res.data.c_user_name
          this.c_user_phone = res.data.c_user_phone
          this.c_user_dzz = res.data.c_user_dzz
          if (!res.data.c_user_phone) {
            this.editinfo = true
          } else {
            this.editinfo = false
          }
        }
      })
    },
    toanswer () {
      this.hassee = 0
    },
    back () {
      let index = this.$route.path.indexOf('/answer-home-new')
      let path = this.$route.path.substring(0, index)
      this.$router.push(path)
    },
    _getCurrentConnect () {
      let _this = this
      getCurrentConnect({ activity_type_new: 3, c_user_guid: this.userGuid, agency_guid: this.agencyGuid, activity_guid: this.activity_guid }).then(res => {
        if (res.code === 200) {
          localStorage.setItem('activity', JSON.stringify(res.data))
          _this.activity = res.data
          _this._getActivitiesDetail()
          _this._useragency()
          _this.anho_st = 'background-image:url(' + res.data.activitySetting.activity_home_bg_img + ')'
          if (res.data.activitySetting.activity_color === 2) {
            _this.btn_st = 'background: #860509;border: 2px solid #C07141;box-shadow: 1px 2px 16px 0px rgba(93,12,15,0.19);color: #FED5A5;'
            _this.btn_st1 = 'background-color: #FED5A5;color: #242121;'
          }
        }
      })
    },
    async _getActivitiesDetail () {
      await getActivitiesDetail({
        activity_guid: this.activity.activity_guid
      }).then(res => {
        if (res.code === 200) {
          this.activityInfo = res.data
        }
      })
    }
  }
}
</script>

<style scoped lang="stylus">
@import "~@/assets/stylus/mixin"
.slide-enter-active, .slide-leave-active
  transition: all 0.3s

.slide-enter, .slide-leave-to
  transform: translate3d(100%, 0, 0)

.answer-home
  position absolute
  z-index 10
  top 0
  left 0
  right 0
  bottom 0
  background #ffffff
  background-size 100% 100%
  background-repeat no-repeat
  .sharebtn{
    width: 183px;
    height: 62px;
    line-height:62px;
    text-align:center;
    background: #FFFFFF;
    border-radius: 30px;
    border: 1px solid #FFFFFF;
    font-weight: 500;
    font-size: 30px;
    color: #2650FC;
    position: absolute;
    top: 47px;
    right: 25px;
  }
  .header
    display flex

    .back
      display flex
      align-items center
      width 160px
      height 84px

      .icon
        width 16px
        height 28px
        margin-left 43px
        //bg-image('~@/assets/img/reading/back-w')
        bg-image('~@/assets/img/reading/back')
        background-size 100% 100%
        background-repeat no-repeat

      .name
        width 100px
        line-height normal
        font-size 26px
        font-weight 500
        color rgba(51, 51, 51, 1)
        margin-left 10px

  .btn
    position absolute
    left 50%
    bottom 206px
    transform translate(-50%, 0)
    display flex
    align-items center
    justify-content center
    width 670px
    height 94px
    background #ffffff
    border-radius 20px
    font-size 32px
    color: #242121;
  .rank
    position absolute
    left 50%
    bottom 90px
    transform translate(-50%, 0)
    display flex
    align-items center
    justify-content center
    width 670px
    height 94px
    background: #2A55F8;
    box-shadow: 1px 2px 16px 0px rgba(75,81,93,0.19);
    border-radius 20px
    font-size 32px
    color #ffffff

  .answer-notice
    position absolute
    left 50%
    bottom 202px
    transform translate(-50%, 0)
    display flex
    justify-content center
    width 670px
    font-size 32px
    line-height 50px
    color #ffffff
  .answer-notice2
    position absolute
    bottom 312px
    display flex
    justify-content center
    width calc(100% - 100px);
    font-size 30px
    line-height 50px
    color #ffffff
    padding:0 50px;
    text-shadow: 2px 2px 2px #000;
</style>
