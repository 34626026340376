import { render, staticRenderFns } from "./AnswerHome.vue?vue&type=template&id=a5a11f86&scoped=true&"
import script from "./AnswerHome.vue?vue&type=script&lang=js&"
export * from "./AnswerHome.vue?vue&type=script&lang=js&"
import style0 from "./AnswerHome.vue?vue&type=style&index=0&id=a5a11f86&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a5a11f86",
  null
  
)

export default component.exports